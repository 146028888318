import React from 'react'
import LoginPage from '../views/login/LoginPage'

const Login = () => {
  return (
    <>
      <LoginPage/>
    </>
  )
}

export default Login
