import React from 'react'
import ChangePassword from '../views/forget-password/ChangePassword'

const ConfirmPassword = () => {
  return (
    <>
        <ChangePassword/>
    </>
  )
}

export default ConfirmPassword
