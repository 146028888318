import React from 'react'
import RegisterPage from '../views/register/RegisterPage'

const Register = () => {
  return (
    <>
      <RegisterPage/>
    </>
  )
}

export default Register
