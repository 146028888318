import React from 'react'
import ForgetPasswordPage from '../views/forget-password/ForgetPasswordPage'

const ForgetPassword = () => {
  return (
    <>
     <ForgetPasswordPage/>
    </>
  )
}

export default ForgetPassword
